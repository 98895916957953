import { observable, action, computed } from 'mobx'
import axios from 'axios';
import { API_HOST } from '../config/_entrypoint';

export class ContactStore {
  @observable loading = false
  @observable loadingContactList = false
  @observable loadingContactFilter = false
  @observable contactsRegistry = new Map()
  @observable contactsNoLoc = new Map()
  @observable FilterTypeContact = new Map()
  // @observable contactsID = {}
  @observable sizeListAll = 0
  @observable checkboxContact = []
  lastParams = 'last'

  constructor(stores) {
    this.stores = stores
  }

  @action resetCheckboxContact(val) {
    if (val) {
      this.getFilterTypeContact.map((fun, idx) =>
        this.checkboxContact.push(fun.id)
      )
    } else {
      this.checkboxContact = []
    }
  }

  @action setFilterSize(size) {
    this.filterSize = size
  }

  @computed get getContacts() {
    return Array.from(this.contactsRegistry.values())
  }

  @computed get getContactsNoLoc() {
    return Array.from(this.contactsNoLoc.values())
  }

  @action setContacts = (contacts) => {
    contacts.forEach(data => {
      if (data.latitude !== null || data.longitude !== null) {
        this.contactsRegistry.set(data['@id'], data)
      } else {
        this.contactsNoLoc.set(data['@id'], data)
      }
    })
  }

  @computed get getSizeListAll() {
    return this.sizeListAll
  }

  @action setSizeListAll = (nb) => {
    this.sizeListAll = nb;
  }

  @action listContact = (nextPage = '') => {
    if (nextPage === '') {
      this.params = ''
      if (this.checkboxContact.length !== 0) {
        for (let i = 0; i < this.checkboxContact.length; i++) {
          this.params += 'function[]=/v2/contact_functions/' + this.checkboxContact[i] + '&'
        }
      }
      if (this.stores.mapStore.region !== '') {
        this.params += 'region=' + this.stores.mapStore.region + '&'
      }
      if (this.stores.mapStore.departement !== '') {
        this.params += 'department.label=' + this.stores.mapStore.departement + '&'
      }
      if (this.stores.mapStore.GeoDistance !== '') {
        this.params += 'GeoDistance_contact=' + this.stores.mapStore.GeoDistance + '&'
      }
      console.log('this.params', this.params)
      this.stores.authStore.checkToken()
      if (this.lastParams !== this.params || this.getSizeListAll.length === 0) {
        this.loadingContactList = true
        this.contactsRegistry = new Map()
        axios.get(`${API_HOST}/contacts?${this.params}page=1`, { headers: this.stores.agent.headers() })
          .then(res => {
            if (res.data['hydra:view']['hydra:next']) {
              this.listContact(res.data['hydra:view']['hydra:next'])
            } else {
              this.loadingContactList = false
            }
            this.setSizeListAll(res.data['hydra:totalItems'])
            this.setContacts(res.data['hydra:member'])
          })
          .catch(err => { throw err })
      }
      this.lastParams = this.params
    } else {
      axios.get(`${API_HOST}${nextPage.replace(/v2\//gi, "")}`, { headers: this.stores.agent.headers() })
        .then(res => {
          if (res.data['hydra:view']['hydra:next'] && this.contactsRegistry.size < 500) {
            this.listContact(res.data['hydra:view']['hydra:next'])
          } else {
            this.loadingContactList = false
          }
          // this.setSizeListAll(res.data['hydra:totalItems'])
          this.setContacts(res.data['hydra:member'])
        })
        .catch(err => { throw err })
    }
  }

  @computed get getFilterTypeContact() {
    return Array.from(this.FilterTypeContact.values())
  }

  @action setFilterTypeContact = (fun) => {
    fun.forEach(data => {
      this.FilterTypeContact.set(data.id, data)
    })
  }

  @action listFilterTypeContact = () => {
    if (this.getFilterTypeContact.length === 0) {
    this.loadingContactFilter = true
    this.stores.authStore.checkToken()
    axios.get(`${API_HOST}/contact_functions`, { headers: this.stores.agent.headers() })
      .then(result => {
        this.setFilterTypeContact(result.data['hydra:member'])
      })
      .catch(err => { this.listFilterTypeContact() })
      .finally(action(() => { this.loadingContactFilter = false }))
    }
  }

  // @computed get getContactsId() {
  //   return this.contactsID
  // }

  // @action setContactsId = (contacts) => {
  //   this.contactsID = {}
  //   this.contactsID = contacts
  // }

  // @action oneContact = (id) => {
  //   this.loading = true

  //   this.stores.authStore.checkToken()
  //   axios.get(`${API_HOST}/contacts/${id}`, { headers : this.stores.agent.headers()})
  //     .then(res => {
  //       this.setContactsId(res.data)
  //     })
  //     .catch(err => { throw err })
  //     .finally(action(() => { this.loading = false }))
  // }

}

export default ContactStore
