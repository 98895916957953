import React, { Component } from 'react';
// import logo from './logo.svg';
// import './App.css';
import { Switch, Route, withRouter } from 'react-router-dom';
import LoginPage from './Login/LoginPage'
import PrivateRoute from './PrivateRoute';
import Layout from './Layout'

@withRouter
export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/login" component={LoginPage} {...this.props} />
          <Route path="/" component={Layout} {...this.props} />
          <Route render={() => <h1>Not Found</h1>} />
        </Switch>
      </React.Fragment>
    );
  }
}