import { observable, computed, action, reaction } from 'mobx';
import jwt_decode from 'jwt-decode';
import agent from './agent';
import axios from 'axios';

class AuthStore {

  constructor(stores) {
    this.stores = stores
    reaction(
      () => this.token,
      token => {
        if (token) {
          window.localStorage.setItem('jwt', token);
          window.localStorage.setItem('refresh_token', this.refresh_token);
        } else {
          window.localStorage.removeItem('jwt');
          window.localStorage.removeItem('refresh_token');
        }
      }
    )
    reaction(
      () => this.xSwitchUser,
      xSwitchUser => {
        if (xSwitchUser) {
          window.localStorage.setItem('SwitchUser', xSwitchUser);
        } else {
          window.localStorage.removeItem('SwitchUser');
        }
      }
    )
  }

  @observable inProgress = false;
  @observable errors = undefined;

  @observable token = window.localStorage.getItem('jwt')
  @observable refresh_token = window.localStorage.getItem('refresh_token')
  @observable xSwitchUser = window.localStorage.getItem('SwitchUser')

  @observable values = {
    id: '',
    username: '',
    role: '',
    level: '',
  };

  @action setToken(token, refresh_token) {
    this.token = token;
    this.refresh_token = refresh_token;
  }

  @action getToken() {
    return this.token
  }

  @computed get decodedToken() {
    if (this.token) {
      return jwt_decode(this.token)
    } else {
      return null
    }
  }

  @action setSwitchUser(xSwitchUser) {
    this.xSwitchUser = xSwitchUser
  }

  @action setUsername(username) {
    this.values.username = username;
  }

  @computed get username() {
    if (this.xSwitchUser !== null) {
      return this.values.impersonatedUsername
    } else {
      return this.values.username
    }
  }
  @computed get email() {
    if (this.xSwitchUser !== null) {
      return this.values.impersonatedEmail
    } else {
      return this.decodedToken.email
    }
  }
  @computed get role() {
    return this.values.role
  }
  @computed get level() {
    if (this.xSwitchUser !== null) {
      return this.values.impersonatedLevel
    } else {
      return this.decodedToken.level
    }
  }
  @computed get id() {
    if (this.xSwitchUser !== null) {
      return this.values.impersonatedId
    } else {
      return this.values.id
    }
  }

  @action setTokenValues() {
    this.values.email = this.decodedToken.email;
    this.values.level = this.decodedToken.level;
    this.values.username = this.decodedToken.fullname;
    this.values.id = this.decodedToken.id;
    this.values.role = this.decodedToken.roles[0];
  }

  @action setEmail(email) {
    this.values.email = email;
  }

  @action setPassword(password) {
    this.values.password = password;
  }

  @action setRole(role) {
    this.values.role = role;
  }

  @action setLevel(level) {
    this.values.level = level;
  }

  @action reset() {
    this.values.username = '';
    this.values.email = '';
  }

  @action login() {
    this.inProgress = true;
    this.errors = undefined;
    // this.stores.ui.clearMessages();
    return agent.Auth.login(this.values.email, this.values.password)
      .then(user => {
        this.setToken(user.token, user.refresh_token)
      })
      .catch(action((err) => {
        if (err.response.status === 401) {
          // this.stores.ui.addMessage('Erreur d\'authentification', 'danger', 'login')
        }
        this.errors = err.response && err.response.body && err.response.body.errors;
        throw err;
      }))
      .finally(action(() => { this.inProgress = false; }));
  }

  @action refresh(refresh_token) {
    this.inProgress = true;
    this.errors = undefined;
    agent.Auth.refresh(refresh_token)
      .then((user) => this.setToken(user.token, user.refresh_token))
      .catch(action((err) => {
        this.errors = err.response && err.response.body && err.response.body.errors;
        throw err;
      }))
      .finally(action(() => {
        this.inProgress = false
      }));
    return Promise.resolve();
  }

  @action register() {
    this.inProgress = true;
    this.errors = undefined;
    return agent.Auth.register(this.values.username, this.values.email, this.values.password)
      .then(({ user }) => this.setToken(user.token))
      .catch(action((err) => {
        this.errors = err.response && err.response.body && err.response.body.errors;
        throw err;
      }))
      .finally(action(() => { this.inProgress = false; }));
  }

  @action logout() {
    this.setToken(undefined, undefined)
    if (this.stores.ui) {
      this.stores.ui.addMessage('Vous êtes maintenant déconnecté', 'warning', 'login');
    }
    return Promise.resolve();
  }

  @action checkToken() {
    if (this.decodedToken && this.decodedToken.exp < ((Date.now() / 1000))) { //Token expired
      this.logout()
    } else if (this.decodedToken && this.decodedToken.exp < ((Date.now() / 1000) + 900)) { //Refresh a moins de 15 minutes du delais
      this.refresh(this.refresh(this.refresh_token))
    }
  }

}

export default AuthStore;
