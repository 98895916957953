import React, { Component } from 'react';
import { inject, observer } from "mobx-react/index"

@inject('authStore')
@observer
class ConnectedFromExtranet extends Component {

  componentDidMount() {
    this.props.authStore.setToken(this.props.match.params.token, this.props.match.params.refresh_token)
    if (this.props.match.params.impersonatedEmail) {
      this.props.authStore.setSwitchUser(this.props.match.params.impersonatedEmail)
    }
    this.props.history.push('/')
  }

  render() {
    return (
      <React.Fragment>
        <div className="loader visible"></div>
      </React.Fragment>
    );
  }
}

export default ConnectedFromExtranet;
