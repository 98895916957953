import React, { Component } from 'react';
import { inject, observer } from "mobx-react/index"
// import Message from '../commons/Message'

@inject('authStore')
@observer
class LoginPage extends Component {

  onChangeEmail = e => this.props.authStore.setEmail(e.target.value);

  onChangePassword = e => this.props.authStore.setPassword(e.target.value);

  onClick = (e) => {
    e.preventDefault();
    if (this.props.authStore.values.email !== '' && this.props.authStore.values.password !== '') {
      this.props.authStore.login()
        .then(() => window.location.replace('/'));
    }
  };

  render() {
    return (
      <React.Fragment>
        <div id="login-background">
          <div className="login-form">
            <img src='./img/logo.png' alt='Logo Pr€t Pro' />
            <form className="login-content">
              {/* <Message zone='login' close={true} /> */}
              <h2>Connexion aux cartes</h2>
              <input type="email" placeholder="Login" onChange={this.onChangeEmail} required />

              <input type="password" placeholder="Mot de passe" onChange={this.onChangePassword} required />

              <button onClick={this.onClick} type="submit" className="button button-primary button-login">Connexion</button>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LoginPage;
