import { Map, TileLayer, MapControl } from 'react-leaflet'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';

class Search extends MapControl {

    createLeafletElement() {
        return GeoSearchControl({
            provider: new OpenStreetMapProvider(),
            style: 'bar',                   // optional: bar|button  - default button
            autoComplete: true,             // optional: true|false  - default true
            autoCompleteDelay: 250,         // optional: number      - default 250
            showMarker: true,               // optional: true|false  - default true
            popupFormat: ({ query, result }) => result.label,   // optional: function    - default returns result label
            showPopup: true,                // optional: true|false  - default false
            autoClose: true,                // optional: true|false  - default false
            retainZoomLevel: false,         // optional: true|false  - default false
            animateZoom: true,              // optional: true|false  - default true
            keepResult: false,              // optional: true|false  - default false
            searchLabel: 'Rechercher'           // optional: string      - default 'Enter address'
        });
    }
}

export default Search;