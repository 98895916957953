import { observable, action, computed } from 'mobx'
import axios from 'axios';
import { API_HOST } from '../config/_entrypoint';

export class LoanFileStore {
  @observable loadingListLoanFile = false
  @observable loadingLoanFileFilter = false
  @observable dossiersRegistry = new Map()
  @observable dossiersNoLoc = new Map()
  @observable FilterTypeLoanFile = new Map()
  @observable sizeListAll = 0
  @observable countLoanFileVisible = 0
  @observable departement = ''
  @observable checkboxLoanFile = []
  lastParams = 'last'

  constructor(stores) {
    this.stores = stores
  }

  @action resetCheckboxLoanFile(val) {
    if (val) {
      this.getFilterTypeLoanFile.map((fun, idx) =>
        this.checkboxLoanFile.push(fun.id)
      )
    } else {
      this.checkboxLoanFile = []
    }
  }

  @computed get getFilterTypeLoanFile() {
    return Array.from(this.FilterTypeLoanFile.values())
  }

  @action setFilterTypeLoanFile = (contacts) => {
    this.FilterTypeLoanFile = new Map()
    contacts.forEach(data => {
      this.FilterTypeLoanFile.set(data['@id'], data)
    })
  }

  @action listFilterTypeLoanFile = () => {
    if (this.getFilterTypeLoanFile.length === 0) {
      this.loadingLoanFileFilter = true
      this.params = ''
      this.stores.authStore.checkToken()
      axios.get(`${API_HOST}/loan_file_statuses?${this.params}page=1`, { headers: this.stores.agent.headers() })
        .then(res => {
          // this.setSizeListAll(res.data['hydra:totalItems'])
          this.setFilterTypeLoanFile(res.data['hydra:member'])
        })
        .catch(err => { throw err })
        .finally(action(() => { this.loadingLoanFileFilter = false }))
    }
  }

  @computed get getDossiers() {
    return Array.from(this.dossiersRegistry.values())
  }

  @computed get getNoLocDossiers() {
    return Array.from(this.dossiersNoLoc.values())
  }

  @action setLoanFile = (dossiers) => {
    dossiers.forEach(data => {
      if (data.latitude !== null || data.longitude !== null) {
        this.dossiersRegistry.set(data['@id'], data)
      } else {
        this.dossiersNoLoc.set(data['@id'], data)
      }
      this.dossiersRegistry.set(data['@id'], data)
    })
  }

  @action listLoanFile = (nextPage = '') => {
    if (nextPage === '') {
      this.params = ''
      if (this.checkboxLoanFile.length !== 0) {
        for (let i = 0; i < this.checkboxLoanFile.length; i++) {
          this.params += 'loanFileStatus.id[]=/v2/loan_file_statuses/' + this.checkboxLoanFile[i] + '&'
        }
      }
      if (this.stores.mapStore.region !== '') {
        this.params += 'region=' + this.stores.mapStore.region + '&'
      }
      if (this.stores.mapStore.departement !== '') {
        this.params += 'postalCode=' + this.stores.mapStore.departement + '&'
      }
      if (this.stores.mapStore.GeoDistance !== '') {
        this.params += 'GeoDistance_loanfile=' + this.stores.mapStore.GeoDistance + '&'
      }
      console.log('this.params', this.params)
      this.stores.authStore.checkToken()
      if (this.lastParams !== this.params || this.getDossiers.length === 0) {
        this.loadingListLoanFile = true
        this.dossiersRegistry = new Map()
        axios.get(`${API_HOST}/loan_files?${this.params}page=1`, { headers: this.stores.agent.headers() })
          .then(res => {
            console.log('result loan_files', res)
            if (res.data['hydra:view']['hydra:next']) {
              this.listLoanFile(res.data['hydra:view']['hydra:next'])
            } else {
              this.loadingListLoanFile = false
            }
            this.setLoanFile(res.data['hydra:member'])
          })
          .catch(err => { throw err })
      }
      this.lastParams = this.params
    } else {
      axios.get(`${API_HOST}${nextPage.replace(/v2\//gi, "")}`, { headers: this.stores.agent.headers() })
        .then(res => {
          if (res.data['hydra:view']['hydra:next'] && this.dossiersRegistry.size < 500) {
            this.listLoanFile(res.data['hydra:view']['hydra:next'])
          } else {
            this.loadingListLoanFile = false
          }
          // this.setSizeListAll(res.data['hydra:totalItems'])
          this.setLoanFile(res.data['hydra:member'])
        })
        .catch(err => { throw err })
    }
  }

}

export default LoanFileStore
