import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from "react-router-dom";
import ApiCalendar from 'react-google-calendar-api';
import SearchAddress from '../commons/SearchAddress'
import SelectDepartement from '../commons/SelectDepartement'
import SelectRegion from '../commons/SelectRegion'
import SearchItinerary from '../commons/SearchItinerary'

@inject('mapStore', 'authStore', 'rdvStore', 'loanFileStore', 'contactStore')
@observer
class SideBarFilter extends Component {

    constructor(props) {
        super(props)
        this.state = { sidebar: true }
    }

    onLogoutClick = (e) => {
        e.preventDefault();
        this.props.authStore.logout()
            .then(this.props.history.push("/login"))
    }

    checkbox(func, checkbox) {
        var test = false
        checkbox.map((fun, idx) => {
            if (fun === func.id) {
                checkbox.splice(idx, 1);
                test = true
            }
        })
        { !test && checkbox.push(func.id) }
        this.forceUpdate()

    }

    isChecked(func, checkbox) {
        var test = false
        checkbox.map((fun) => {
            if (fun === func.id) {
                test = true
            }
        })
        return test
    }

    setDossier() {
        if (this.props.mapStore.tab !== 1) {
            this.props.mapStore.setTab(1)
            // this.props.contactStore.listContact()
        }
    }

    setContact() {
        if (this.props.mapStore.tab !== 2) {
            this.props.mapStore.setTab(2)
            // this.props.loanFileStore.listLoanFile()
        }
    }

    setRdv(refresh) {
        if (this.props.mapStore.tab !== 3 || refresh) {
            this.props.mapStore.setTab(3)
            if (ApiCalendar.sign) {
                ApiCalendar.listUpcomingEvents(10)
                    .then(result => {
                        this.props.rdvStore.setRdv(result.result.items)
                    })
            } else {
                ApiCalendar.handleAuthClick()
            }
        }
    }

    render() {
        const { mapStore, authStore, loanFileStore, contactStore, rdvStore } = this.props
        const { filterSize, tab, switchButton } = this.props.mapStore
        const { getRdv, loadingRdvList, getRdvMarker, getRdvNoLoc } = this.props.rdvStore
        const { loadingContactList, loadingContactFilter, getContacts, getContactsNoLoc, getFilterTypeContact } = this.props.contactStore
        const { loadingListLoanFile, loadingLoanFileFilter, getFilterTypeLoanFile, getDossiers, getNoLocDossiers } = this.props.loanFileStore
        return (
            <React.Fragment>
                <div id="sidebar-filter" className={this.state.sidebar ? 'visible' : 'cached'}>
                    <div id="options">
                        <div className="options-btn" onClick={() => { this.setState({ sidebar: !this.state.sidebar }) }}> <i className="fa fa-list-ul"></i> </div>
                        <div className="options-btn" onClick={this.onLogoutClick}> <i className="fa fa-power-off"></i> </div>
                    </div>
                    <div className="search-by">
                        <span>Rercher par :</span>
                        <span className={filterSize === "region" ? "search-by-btn active" : "search-by-btn"} onClick={() => { mapStore.setFilterSize('region') }}>Région</span>
                        <span>|</span>
                        <span className={filterSize === "departement" ? "search-by-btn active" : "search-by-btn"} onClick={() => { mapStore.setFilterSize('departement') }}>Département</span>
                        <span>|</span>
                        <span className={filterSize === "adresse" ? "search-by-btn active" : "search-by-btn"} onClick={() => { mapStore.setFilterSize('adresse') }}>Adresse</span>
                        {authStore.decodedToken &&
                            <React.Fragment>
                                <span>|</span>
                                <span className={filterSize === "itineraire" ? "search-by-btn active" : "search-by-btn"} onClick={() => { mapStore.setFilterSize('itineraire') }}>Itinéraire</span>
                            </React.Fragment>
                        }
                    </div>
                    <div className="select">
                        {filterSize === "departement" && <SelectDepartement />}
                        {filterSize === "region" && <SelectRegion />}
                        {filterSize === "adresse" && <SearchAddress placeholder="Saisissez une adresse (rayon : 100km)" />}
                        {filterSize === "itineraire" && authStore.decodedToken && <SearchItinerary />}
                    </div>
                    <div id="list-container">
                        <div className="list">
                            <div className="list-item">
                                {tab === 1 &&
                                    <React.Fragment>
                                        {getFilterTypeLoanFile.map((fun, idx) =>
                                            <React.Fragment key={idx}>
                                                {fun.loanFilesCount !== 0 &&
                                                    <div>
                                                        <input type="checkbox" id={fun.id} name={fun.id} checked={this.isChecked(fun, loanFileStore.checkboxLoanFile) ? true : false} onChange={() => { this.checkbox(fun, loanFileStore.checkboxLoanFile) }} />
                                                        <label htmlFor={fun.id}>{fun.label} {'(' + fun.loanFilesCount + ')'}</label>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        )}
                                        <div className="option-checkbox">
                                            <span onClick={() => { loanFileStore.resetCheckboxLoanFile(true) }}>Tout cocher</span>
                                            |
                                            <span onClick={() => { loanFileStore.resetCheckboxLoanFile() }}>Tout décocher</span>
                                        </div>
                                    </React.Fragment>
                                }
                                {tab === 2 &&
                                    <React.Fragment>
                                        {getFilterTypeContact.map((fun, idx) =>
                                            <React.Fragment key={idx}>
                                                {fun.contactsCount !== 0 &&
                                                    <div>
                                                        <input type="checkbox" id={fun.id} name={fun.id} checked={this.isChecked(fun, contactStore.checkboxContact) ? true : false} onChange={() => { this.checkbox(fun, contactStore.checkboxContact) }} />
                                                        <label htmlFor={fun.id}>{fun.label} {'(' + fun.contactsCount + ')'}</label>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        )}
                                        <div className="option-checkbox">
                                            <span onClick={() => { contactStore.resetCheckboxContact(true) }}>Tout cocher</span>
                                            |
                                            <span onClick={() => { contactStore.resetCheckboxContact() }}>Tout décocher</span>
                                        </div>
                                    </React.Fragment>
                                }
                                {tab === 3 && authStore.decodedToken &&
                                    <React.Fragment>
                                        {getRdv.map((event, idx) =>
                                            <React.Fragment key={idx}>
                                                <li>
                                                    <span>{event.summary}</span>
                                                </li>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={authStore.decodedToken ? "sidebar-footer" : "sidebar-footer two"}>
                        {tab === 1 && (loadingListLoanFile || loadingLoanFileFilter) && <div className="loader visible"></div>}
                        {tab === 2 && (loadingContactList || loadingContactFilter) && <div className="loader visible"></div>}
                        {tab === 3 && authStore.decodedToken && loadingRdvList && <div className="loader visible"></div>}
                        <nav>
                            <span className={tab === 1 ? 'active' : undefined} onClick={() => { this.setDossier() }}>Financements</span>
                            <span className={tab === 2 ? 'active' : undefined} onClick={() => { this.setContact() }}>Contacts</span>
                            {authStore.decodedToken &&
                                <span className={tab === 3 ? 'active' : undefined} onClick={() => { this.setRdv() }}>Rendez-vous</span>
                            }
                        </nav>
                        {tab !== 3 &&
                        <div className="container-switch-button" onClick={() => { mapStore.toggleSwitch() }}>
                            <div>
                                <span className={switchButton && "active"}>Contacts & Financements</span>
                                <span className="switch-button">
                                    <span className={switchButton ? "switch-round on" : "switch-round off"}></span>
                                </span>
                                {tab === 1 && <span className={!switchButton && "active"}>Financements uniquement</span> }
                                {tab === 2 && <span className={!switchButton && "active"}>Contacts uniquement</span> }
                            </div>
                        </div>
                        }
                        {tab === 1 &&
                            <React.Fragment>
                                <button type="button" className="button button-primary button-login" onClick={() => { loanFileStore.listLoanFile() }}>Afficher votre selection</button>
                                <span className="info-count">{getDossiers.length} dossier{getDossiers.length !== 0 && 's'} affichés / <Link to={{ pathname: '/no-loc', state: { type: 'financements' } }}>{getNoLocDossiers.length} dossier{getNoLocDossiers.length !== 0 && 's'} non localisés</Link></span>
                            </React.Fragment>
                        }
                        {tab === 2 &&
                            <React.Fragment>
                                <button type="button" className="button button-primary button-login" onClick={() => { contactStore.listContact() }}>Afficher votre selection</button>
                                <span className="info-count">{getContacts.length} contact{getContacts.length !== 0 && 's'} affichés / <Link to={{ pathname: '/no-loc', state: { type: 'contacts' } }}>{getContactsNoLoc.length} contact{getContactsNoLoc.length !== 0 && 's'} non localisés</Link></span>
                            </React.Fragment>
                        }
                        {tab === 3 && authStore.decodedToken &&
                            <React.Fragment>
                                <button type="button" className="button button-primary button-login" onClick={() => { this.setRdv(true) }}>Afficher votre selection</button>
                                <span className="info-count">{getRdvMarker.length} rdv{getRdvMarker.length !== 0 && 's'} affichés / <Link to={{ pathname: '/no-loc', state: { type: 'rendez-vous' } }}>{getRdvNoLoc.length} rdv{getRdvNoLoc.length !== 0 && 's'} non localisés</Link></span>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SideBarFilter
