import { observable, action, computed } from 'mobx'
import axios from 'axios';
import { API_HOST } from '../config/_entrypoint';

export class MapStore {
  @observable loading = false
  @observable allDepartement = new Map()
  @observable allRegion = new Map()
  @observable sizeListAll = 0
  @observable filterSize = 'departement'
  @observable departement = ''
  @observable region = ''
  @observable GeoDistance = ''
  @observable searchResultAddress = new Map()
  @observable markerResultAddress = []
  @observable startAddressItinary = []
  @observable finishAddressItinary = []
  @observable stepAddressItinary = []
  @observable numStepAddressItinary = 0
  @observable tab = 2
  @observable itinary = false
  @observable switchButton = false

  constructor(stores) {
    this.stores = stores
  }

  @action toggleSwitch() {
    this.switchButton = !this.switchButton
  }

  @action setTab(val) {
    this.tab = val
  }

  @action addStep() {
    if (this.numStepAddressItinary === 0 || this.stepAddressItinary[this.numStepAddressItinary-1] !== undefined) {
      this.numStepAddressItinary++
    }
  }

  @action removeStep() {
    this.numStepAddressItinary--
  }

  @action setDepartement(dep) {
    if(dep === '0') {
      this.departement = ''
    } else {
      this.departement = dep
    }
  }

  @action setRegion(region) {
    console.log('region', region)
    if(region === '0') {
      this.region = ''
    } else {
      this.region = region
    }
  }

  @action setFilterSize(size) {
    this.filterSize = size
  }

  @computed get getStartAddressItinary() {
    return this.startAddressItinary
  }

  @action setStartAddressItinary = (x, y) => {
    this.startAddressItinary = [x, y]
  }

  @computed get getStepAddressItinary() {
    return this.stepAddressItinary
  }

  @action setStepAddressItinary = (x, y, id) => {
    this.stepAddressItinary[id] = [x, y]
  }

  @computed get getFinishAddressItinary() {
    return this.finishAddressItinary
  }

  @action setFinishAddressItinary = (x, y) => {
    this.finishAddressItinary = [x, y]
  }

  @computed get getAllDepartement() {
    return Array.from(this.allDepartement.values())
  }

  @action setAllDepartement = (dep) => {
    dep.forEach(data => {
      this.allDepartement.set(data.id, data)
    })
  }

  @action picklistDepartement = () => {
    if (this.getAllDepartement.length === 0) {
      this.stores.authStore.checkToken()
      axios.get(`${API_HOST}/departments`, { headers: this.stores.agent.headers() })
        .then(result => {
          this.setAllDepartement(result.data['hydra:member'])
        })
        .catch(err => { throw err })
    }
  }

  @computed get getAllRegion() {
    return Array.from(this.allRegion.values())
  }

  @action setAllRegion = (dep) => {
    dep.forEach(data => {
      this.allRegion.set(data.id, data)
    })
  }

  @action picklistRegion = () => {
    if (this.getAllRegion.length === 0) {
      this.stores.authStore.checkToken()
      axios.get(`${API_HOST}/picklists_compats?sfObject=Financements__c&name=R_gion_du_dossier__c`, { headers: this.stores.agent.headers() })
      .then(result => {
          this.setAllRegion(result.data['hydra:member'])
        })
        .catch(err => { throw err })
    }
  }

  @computed get getMarkerResultAddress() {
    return this.markerResultAddress
  }

  @action resetMarkerResultAddress() {
    this.markerResultAddress = []
    this.GeoDistance = ''
  }

  @action setMarkerResultAddress = (x, y) => {
    this.markerResultAddress = [x, y]
    this.GeoDistance = x+','+y+',100'
  }

  @computed get getSearchResultAddress() {
    return Array.from(this.searchResultAddress.values())
  }

  @action resetSearchResultAddress() {
    this.searchResultAddress = new Map()
  }

  @action setSearchResultAddress = (result) => {
    this.searchResultAddress = new Map()
    result.forEach(data => {
      var id = data.geometry.coordinates["1"] + data.geometry.coordinates["0"]
      this.searchResultAddress.set(id, data)
    })
  }

  @action search = (text) => {
    if (text !== '') {
      var address = text.replace(/ /g, "+")
      axios.get(`https://api-adresse.data.gouv.fr/search/?q=${address}`)
        .then(res => {
          this.setSearchResultAddress(res.data.features)
        })
        .catch(err => { console.log('ERROR API GOUV') })
      // .finally(action(() => { console.log('finally API GOUV') }))
    }
  }

  @action calculateRoute = () => {
    console.log('Départ : ', this.startAddressItinary)
    for (let i = 0; i < this.stepAddressItinary.length; i++) {
      console.log('Step : ' + i + ' ', this.stepAddressItinary[i])
    }
    console.log('Arrivée : ', this.finishAddressItinary)
    var startLat = this.startAddressItinary[1]
    var startLong = this.startAddressItinary[0]
    var finishLat = this.finishAddressItinary[1]
    var finishLong = this.finishAddressItinary[0]

    if (this.startAddressItinary.length !== 0 && this.finishAddressItinary.length !== 0) {
      this.itinary = true
      // axios.get(`http://wxs.ign.fr/CLEF/itineraire/rest/route.json?origin=${startLat},${startLong}&destination=${finishLat},${finishLong}&&method=DISTANCE&graphName=Voiture`)
      // axios.get(`https://maps.open-street.com/api/route/?origin=${startLong},${startLat}&destination=${finishLong},${finishLat}&mode=driving&key=cle-fournie`)
      //   .then(result => {
      //     console.log('resultat itinéraire', result)
      //   })
    }
  }

  @action resetAddressItinary() {
    this.itinary = false
    this.startAddressItinary = []
    this.finishAddressItinary = []
    this.stepAddressItinary = []
    this.numStepAddressItinary = 0
  }

}

export default MapStore
