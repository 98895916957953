import { observable, action, computed } from 'mobx'
import axios from 'axios';

export class RdvStore {
  @observable loadingRdvList = false
  @observable RdvRegistry = new Map()
  @observable rdvMarkerRegistry = new Map()
  @observable rdvNoLocRegistry = new Map()

  constructor(stores) {
    this.stores = stores
  }

  @computed get getRdv() {
    return Array.from(this.RdvRegistry.values())
  }

  @action setRdv = (rdv) => {
    this.loadingRdvList = true
    this.RdvRegistry = new Map()
    this.rdvMarkerRegistry = new Map()
    this.rdvNoLocRegistry = new Map()
    rdv.forEach(data => {
      this.RdvRegistry.set(data.id, data)
      if (data.location !== undefined) {
        var address = data.location.replace(/ /g, "+")
        axios.get(`https://api-adresse.data.gouv.fr/search/?q=${address}`)
          .then(res => {
            if (this.stores.mapStore.departement !== '' && this.stores.mapStore.departement === res.data.features[0].properties.citycode.substr(0, 2)) {
              this.setListRdv(data.id, res.data.features[0])
            } else if (this.stores.mapStore.region !== '') {
              if (this.stores.mapStore.region === "Pays de la Loire") {
                if (res.data.features[0].properties.context.indexOf("Pays-de-la-Loire") > 0) {
                  this.setListRdv(data.id, res.data.features[0])
                } else {
                  this.rdvNoLocRegistry.set(data.id, data)
                }
              } else {
                if (res.data.features[0].properties.context.indexOf(this.stores.mapStore.region) > 0) {
                  this.setListRdv(data.id, res.data.features[0])
                } else {
                  this.rdvNoLocRegistry.set(data.id, data)
                }
              }
            } else if (this.stores.mapStore.getMarkerResultAddress.length !== 0) {
              if (this.get_distance_m(res.data.features[0].geometry.coordinates["1"], res.data.features[0].geometry.coordinates["0"], this.stores.mapStore.getMarkerResultAddress[0], this.stores.mapStore.getMarkerResultAddress[1])/1000 < 100) {
                this.setListRdv(data.id, res.data.features[0])
              } else {
                this.rdvNoLocRegistry.set(data.id, data)
              }
            } else if (this.stores.mapStore.region === '' && this.stores.mapStore.departement === '' && this.stores.mapStore.getMarkerResultAddress.length === 0) {
              this.setListRdv(data.id, res.data.features[0])
            }
          })
          .finally(action(() => { this.loadingRdvList = false }))
      } else {
        this.rdvNoLocRegistry.set(data.id, data)
      }
    })
  }

  deg2rad(x) {
    return Math.PI * x / 180;
  }

  get_distance_m(lat1, lng1, lat2, lng2) {
    var earth_radius = 6378137 // Terre = sphère de 6378km de rayon
    var rlo1 = this.deg2rad(lng1) // CONVERSION
    var rla1 = this.deg2rad(lat1)
    var rlo2 = this.deg2rad(lng2)
    var rla2 = this.deg2rad(lat2)
    var dlo = (rlo2 - rlo1) / 2
    var dla = (rla2 - rla1) / 2
    var a = (Math.sin(dla) * Math.sin(dla)) + Math.cos(rla1) * Math.cos(rla2) * (Math.sin(dlo) * Math.sin(dlo))
    var d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return (earth_radius * d)
  }

  @computed get getRdvMarker() {
    return Array.from(this.rdvMarkerRegistry.values())
  }

  @computed get getRdvNoLoc() {
    return Array.from(this.rdvNoLocRegistry.values())
  }

  @action setListRdv = (id, data) => {
    data.id = id
    this.rdvMarkerRegistry.set(data.id, data)
  }

}

export default RdvStore