import React, { Component } from 'react';
import { inject, observer } from "mobx-react/index"
import { Popup } from 'react-leaflet'
import moment from 'moment'
import 'moment/locale/fr';

@inject('rdvStore', 'authStore')
@observer
class MyPopup extends Component {

    render() {
        const { type, data, group, authStore } = this.props
        return (
            <Popup>
                <div className="gm-style">
                    {type === 'dossier' &&
                        <div className="single">
                            <table>
                                {authStore.decodedToken ?
                                    <React.Fragment>
                                        <thead><tr><td colSpan={2}><a href={'https://eu1.salesforce.com/' + data.salesforceId} title='Modifier le dossier en ligne' target='_blank'>{data.name}</a></td></tr></thead>
                                        <tbody>
                                            <tr><td>&Eacute;tat</td><td>{data.loanFileStatus.label}</td></tr> {/* etat */}
                                            <tr><td>Créé le</td><td>{moment(data.createdDate).format("DD/MM/YYYY")}</td></tr> {/* date_creation */}
                                            {data.account && <tr><td>Compte</td><td><a href={'https://eu1.salesforce.com/' + data.account.salesforceId} title='Modifier le compte en ligne' target='_blank'><strong>{data.account.name}</strong></a></td></tr>}
                                            {data.client &&
                                                <tr>
                                                    <td>Contact</td>
                                                    <td>
                                                        <ul>
                                                            <li><a href={'https://eu1.salesforce.com/' + data.client.salesforceId} title='Modifier le contact en ligne' target='_blank'><strong>{data.client.firstName}&nbsp;{data.client.lastName}</strong></a></li>
                                                            {data.client.phone && <li>{data.client.phone}</li>}
                                                            {data.client.mobilePhone && <li>{data.client.mobilePhone}</li>}
                                                            {data.client.email && <li><a href={'mailto:' + data.client.email}>{data.client.email}</a></li>}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            } {/* contact */}
                                            {data.frontOffice &&
                                                <tr>
                                                    <td>Front office</td><td>
                                                        <ul>
                                                            <li><strong>{data.frontOffice.firstName && data.frontOffice.firstName + ' '} {data.frontOffice.lastName && data.frontOffice.lastName}</strong></li>
                                                            {data.frontOffice.phone && <li>{data.frontOffice.phone}</li>}
                                                            {data.frontOffice.mobilePhone && <li>{data.frontOffice.mobilePhone}</li>}
                                                            {data.frontOffice.email && <li><a href={'mailto:' + data.frontOffice.email}>{data.frontOffice.email}</a></li>}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            } {/* front office */}
                                            {data.fundingAmount || data.billings.mandateAmount ?
                                                <tr>
                                                    <td className='financements_label'>Financements</td>
                                                    <td>
                                                        <ul>
                                                            <li>Prêt : {data.fundingAmount && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(data.fundingAmount)}</li>
                                                            <li>Mandat : {data.billings.mandateAmount && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(data.billings.mandateAmount)}</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td className='financements_label'>Financements</td>
                                                    <td>Non définis</td>
                                                </tr>
                                            } {/* financement */}
                                        </tbody>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <tbody>
                                            <tr><td>{data.loanFileStatus.label}</td></tr> {/* etat */}
                                            {data.client &&
                                                <tr>
                                                    <td>
                                                        <ul>
                                                            <li><strong><strong>{data.client.firstName}&nbsp;{data.client.lastName}</strong></strong></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            } {/* contact */}
                                            {(data.fundingAmount || data.billings.mandateAmount) &&
                                                <tr>
                                                    <td className='financements_label'>Financements</td>
                                                    <td>
                                                        <ul>
                                                            <li>Prêt : {data.fundingAmount && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(data.fundingAmount)}</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            } {/* financement */}
                                        </tbody>
                                    </React.Fragment>
                                }
                            </table>
                        </div>
                    }
                    {type === 'contact' &&
                        <React.Fragment>
                            {group.length === 0 ?
                                <React.Fragment>
                                    <div className="single">
                                        <table>
                                            <thead><tr>{data.account ? <td colSpan={2} className='compte_nom'>{data.account.name}</td> : <td colSpan={2}>Sans titre</td>}</tr></thead>
                                            <tbody>
                                                <tr><td>Nom</td><td><a href={'https://eu1.salesforce.com/' + data.salesforceId} title='Modifier le contact en ligne' target='_blank'><strong>{data.firstName}&nbsp;{data.lastName}</strong></a></td></tr>
                                                {data.function.label && <tr><td>Fonction</td><td>{data.function.label}</td></tr>}
                                                <tr>
                                                    <td>Adresse</td>
                                                    <td>
                                                        <ul>
                                                            {data.mailingStreet && <li>{data.mailingStreet}</li>}
                                                            {data.mailingPostalCode && <li>{data.mailingPostalCode}</li>}
                                                            {data.mailingCountry && <li>{data.mailingCountry}</li>}
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr><td className='coordonnees'>Coordonnées</td>
                                                    <td>
                                                        <ul>
                                                            {data.phone && <li>{data.phone}</li>}
                                                            {data.mobilePhone && <li>{data.mobilePhone}</li>}
                                                            {data.email && <li><a className='mail' href={'mailto:' + data.email}>{data.email}</a></li>}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className="group">
                                        <div className="single">
                                            <table>
                                                <thead><tr>{data.account ? <td colSpan={2} className='compte_nom'>{data.account.name}</td> : <td colSpan={2}>Sans titre</td>}</tr></thead>
                                                <tbody>
                                                    <tr><td>Nom</td><td><a href={'https://eu1.salesforce.com/' + data.salesforceId} title='Modifier le contact en ligne' target='_blank'><strong>{data.firstName}&nbsp;{data.lastName}</strong></a></td></tr>
                                                    {data.function.label && <tr><td>Fonction</td><td>{data.function.label}</td></tr>}
                                                    <tr>
                                                        <td>Adresse</td>
                                                        <td>
                                                            <ul>
                                                                {data.mailingStreet && <li>{data.mailingStreet}</li>}
                                                                {data.mailingPostalCode && <li>{data.mailingPostalCode}</li>}
                                                                {data.mailingCountry && <li>{data.mailingCountry}</li>}
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr><td className='coordonnees'>Coordonnées</td>
                                                        <td>
                                                            <ul>
                                                                {data.phone && <li>{data.phone}</li>}
                                                                {data.mobilePhone && <li>{data.mobilePhone}</li>}
                                                                {data.email && <li><a className='mail' href={'mailto:' + data.email}>{data.email}</a></li>}
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {group.map((data, idx) =>
                                            <React.Fragment key={idx}>
                                                <div className="single">
                                                    <table>
                                                        <thead><tr>{data.account ? <td colSpan={2} className='compte_nom'>{data.account.name}</td> : <td colSpan={2}>Sans titre</td>}</tr></thead>
                                                        <tbody>
                                                            <tr><td>Nom</td><td><a href={'https://eu1.salesforce.com/' + data.salesforceId} title='Modifier le contact en ligne' target='_blank'><strong>{data.firstName}&nbsp;{data.lastName}</strong></a></td></tr>
                                                            {data.function.label && <tr><td>Fonction</td><td>{data.function.label}</td></tr>}
                                                            <tr>
                                                                <td>Adresse</td>
                                                                <td>
                                                                    <ul>
                                                                        {data.mailingStreet && <li>{data.mailingStreet}</li>}
                                                                        {data.mailingPostalCode && <li>{data.mailingPostalCode}</li>}
                                                                        {data.mailingCountry && <li>{data.mailingCountry}</li>}
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                            <tr><td className='coordonnees'>Coordonnées</td>
                                                                <td>
                                                                    <ul>
                                                                        {data.phone && <li>{data.phone}</li>}
                                                                        {data.mobilePhone && <li>{data.mobilePhone}</li>}
                                                                        {data.email && <li><a className='mail' href={'mailto:' + data.email}>{data.email}</a></li>}
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody></table>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                    {type === 'rdv' &&
                        <div className="single">
                            <table>
                                {this.props.rdvStore.getRdv.map((rdv, idx) =>
                                    <React.Fragment key={idx}>
                                        {rdv.id === data.id &&
                                            <React.Fragment>
                                                <thead>
                                                    <tr><td colSpan={2}><a href={rdv.htmlLink} title='Voir le rendez-vous dans votre agenda' target='_blank'>{rdv.summary}</a></td></tr>
                                                </thead>
                                                <tbody>
                                                    {rdv.start.dateTime && <tr><td>Début</td><td className='date'>{rdv.start.dateTime}</td></tr>}
                                                    {rdv.end.dateTime && <tr><td>Fin</td><td className='date'>{rdv.end.dateTime}</td></tr>}
                                                    {rdv.location && <tr><td>Lieu</td><td>{rdv.location}</td></tr>}
                                                    {rdv.attendees &&
                                                        <tr><td className='attendees'>{rdv.attendees.length !== 0 ? 'Présents' : 'Présent'}</td><td><ul>
                                                            {rdv.attendees.map((attendee, idx) =>
                                                                <React.Fragment key={idx}>
                                                                    <li>{attendee.email}</li>
                                                                </React.Fragment>
                                                            )}
                                                        </ul></td></tr>
                                                    }
                                                    {rdv.description && <tr><td colSpan={2} className='description'>{rdv.description}</td></tr>}
                                                </tbody>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                )
                                }
                            </table>
                        </div>
                    }
                </div>
            </Popup >
        )
    }
}

export default MyPopup;