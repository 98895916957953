import React from 'react';
import ReactDom from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import history from './history';

import agent from './stores/agent';
import authStore from './stores/AuthStore'
import MapStore from './stores/MapStore'
import RdvStore from './stores/RdvStore'
import LoanFileStore from './stores/LoanFileStore'
import ContactStore from './stores/ContactStore'

class RootStore {
    constructor() {
        this.agent = new agent.Agent(this)
        this.authStore = new authStore(this)
        this.mapStore = new MapStore(this)
        this.rdvStore = new RdvStore(this)
        this.loanFileStore = new LoanFileStore(this)
        this.contactStore = new ContactStore(this)
    }
}
const stores = new RootStore()

ReactDom.render(
    <Provider {...stores}>
        <Router history={history}>
            <App history={history} />
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
