import React, { Component } from 'react';
import { inject, observer } from "mobx-react/index"
import { Map, Marker, TileLayer, withLeaflet, Circle } from 'react-leaflet'
import L from 'leaflet'

import SideBarFilter from './SidebarFilter'
import Search from './Search'
import MyPopup from './MyPopup';
// import Routing from './Routing'

// import Routing from './../commons/RoutingMachine';

@inject('authStore', 'mapStore', 'rdvStore', 'loanFileStore', 'contactStore')
@observer
class HomePage extends Component {

  constructor(props) {
    super(props)
    this.state = { lengende: false }
  }

  componentDidMount() {
    // this.props.contactStore.listContact()
    this.props.contactStore.listFilterTypeContact()
    this.props.loanFileStore.listFilterTypeLoanFile()
    this.props.mapStore.picklistDepartement()
    this.props.mapStore.picklistRegion()
  }

  componentWillMount() {
    document.title = "Géolocalisation de vos contacts et dossiers de financements - Pr€t Pro"
  }

  getMarkerDossier(dossier) {
    const AttenteConfClient = L.icon({ iconUrl: require('./Marker/euro-orange.png'), iconSize: [35, 37], iconAnchor: [0, 37], popupAnchor: [17, -37] })
    const AContacter = L.icon({ iconUrl: require('./Marker/euro-rouge.png'), iconSize: [35, 37], iconAnchor: [0, 37], popupAnchor: [17, -37] })
    const Constitution30 = L.icon({ iconUrl: require('./Marker/euro-vert-alert.png'), iconSize: [35, 37], iconAnchor: [0, 37], popupAnchor: [17, -37] })
    const Constitution = L.icon({ iconUrl: require('./Marker/euro-vert.png'), iconSize: [35, 37], iconAnchor: [0, 37], popupAnchor: [17, -37] })
    const EnvoyeAttenteBanque = L.icon({ iconUrl: require('./Marker/euro-bleu-clair.png'), iconSize: [35, 37], iconAnchor: [0, 37], popupAnchor: [17, -37] })
    const AccordeFinance = L.icon({ iconUrl: require('./Marker/euro-bleu-fonce.png'), iconSize: [35, 37], iconAnchor: [0, 37], popupAnchor: [17, -37] })

    switch (dossier.loanFileStatus.label) {
      case ('En attente de confirmation client'):
        return (
          <Marker
            position={[dossier.latitude, dossier.longitude]}
            icon={AttenteConfClient}
          >
            <MyPopup type='dossier' data={dossier} />
          </Marker>
        )
        break;
      case ('Candidat(e) à contacter'):
        return (
          <Marker
            position={[dossier.latitude, dossier.longitude]}
            icon={AContacter}
          >
            <MyPopup type='dossier' data={dossier} />
          </Marker>
        )
        break;
      case ('En cours de constitution - (Rdv banques dans les 30 jours)'):
        return (
          <Marker
            position={[dossier.latitude, dossier.longitude]}
            icon={Constitution30}
          >
            <MyPopup type='dossier' data={dossier} />
          </Marker>
        )
        break;
      case ('En cours de constitution'):
      case ('En cours'):
        return (
          <Marker
            position={[dossier.latitude, dossier.longitude]}
            icon={Constitution}
          >
            <MyPopup type='dossier' data={dossier} />
          </Marker>
        )
        break;
      case ('Envoyé en banque'):
      case ('En attente de réponse bancaire'):
        return (
          <Marker
            position={[dossier.latitude, dossier.longitude]}
            icon={EnvoyeAttenteBanque}
          >
            <MyPopup type='dossier' data={dossier} />
          </Marker>
        )
        break;
      case ('Accordé en banque'):
      case ('Financé'):
      case ('Financés'):
        return (
          <Marker
            position={[dossier.latitude, dossier.longitude]}
            icon={AccordeFinance}
          >
            <MyPopup type='dossier' data={dossier} />
          </Marker>
        )
        break;
      default:
        return null
    }

  }

  getMarkerContact(contact) {
    var group = false
    var groupDetail = []
    this.props.contactStore.getContacts.map((eachContact) => {
      if (contact.latitude === eachContact.latitude && contact.longitude === eachContact.longitude && contact.id !== eachContact.id) {
        group = true
        groupDetail.push(eachContact)
      }
    })
    return (
      <Marker
        position={[contact.latitude, contact.longitude]}
        icon={this.getIcon(contact.geocodeAccuracy, group)}
      >
        <MyPopup type='contact' data={contact} group={groupDetail} />
      </Marker>
    )
  }

  getMarkerRdv(rdv) {
    var group = false
    this.props.rdvStore.getRdvMarker.map((eachRdv) => {
      if (eachRdv.geometry.coordinates["0"] === rdv.geometry.coordinates["0"] && rdv.id !== eachRdv.id && eachRdv.geometry.coordinates["1"] === rdv.geometry.coordinates["1"]) {
        group = true
      }
    })

    return (
      <Marker
        position={[rdv.geometry.coordinates["1"], rdv.geometry.coordinates["0"]]}
        icon={group ?
          L.icon({ iconUrl: require('./Marker/rdv-orange.png'), iconSize: [35, 37], iconAnchor: [0, 0] })
          :
          L.icon({ iconUrl: require('./Marker/rdv-bleu-fonce.png'), iconSize: [35, 37], iconAnchor: [0, 0] })}
      >
        <MyPopup type='rdv' data={rdv} />
      </Marker>
    )
  }

  getIcon(icon, group) {
    const customMarkerOk = L.icon({ iconUrl: require('./Marker/contact-bleu-fonce.png'), iconSize: [35, 37], iconAnchor: [35, 37], popupAnchor: [-17, -37] })
    const customGroupMarkerOk = L.icon({ iconUrl: require('./Marker/group-bleu-fonce.png'), iconSize: [35, 37], iconAnchor: [35, 37], popupAnchor: [-17, -37] })
    const customMarkerMoyen = L.icon({ iconUrl: require('./Marker/contact-orange.png'), iconSize: [35, 37], iconAnchor: [35, 37], popupAnchor: [-17, -37] })
    const customGroupMarkerMoyen = L.icon({ iconUrl: require('./Marker/group-orange.png'), iconSize: [35, 37], iconAnchor: [35, 37], popupAnchor: [-17, -37] })
    switch (icon) {
      case ('Address'):
      case ('NearAddress'):
      case ('Block'):
      case ('Street'):
        if (group) {
          return customGroupMarkerOk
        } else {
          return customMarkerOk
        }
        break;
      case ('ExtendedZip'):
      case ('Zip'):
      case ('Postal Code'):
      case ('Neighborhood'):
      case ('City'):
      case ('County'):
        if (group) {
          return customGroupMarkerMoyen
        } else {
          return customMarkerMoyen
        }
        break;
      case ('State'):
      case ('Province'):
      case ('Unknown'):
      case (null):
        return null
        break;
    }
  }

  legendeVisible() {
    this.setState({ lengende: !this.state.lengende })
  }

  render() {
    const { getDossiers } = this.props.loanFileStore
    const { getContacts } = this.props.contactStore
    const { getRdvMarker } = this.props.rdvStore
    const position = [48.8534, 2.3488]
    const AddressSearch = withLeaflet(Search);
    const { tab, getMarkerResultAddress, itinary, startAddressItinary, finishAddressItinary, stepAddressItinary, switchButton } = this.props.mapStore
    return (
      <React.Fragment>
        <SideBarFilter {...this.props} />
        <section id="home" className="wrapper-content carte">
          <div id="mapid" className="leaflet-container">
            {/* Carte */}
            <Map center={position} zoom={6} ref={map => this.map = map}>
              <AddressSearch />
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                // url="http://stamen-tiles-{s}.a.ssl.fastly.net/toner-background/{z}/{x}/{y}.png"
                // url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
                attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
              />
              {/* <Routing map={this.map} /> */}
              {getMarkerResultAddress.length !== 0 &&
                <React.Fragment>
                  <Circle
                    center={getMarkerResultAddress}
                    radius={100000}
                  />
                </React.Fragment>
              }
              {itinary &&
                <React.Fragment>
                  <Marker
                    position={[startAddressItinary[0], startAddressItinary[1]]}
                  />
                  {
                    stepAddressItinary.map((step, idx) =>
                      <Marker
                        position={[step[0], step[1]]}
                      />
                    )
                  }
                  <Marker
                    position={[finishAddressItinary[0], finishAddressItinary[1]]}
                  />
                </React.Fragment>
              }
              {(tab === 1 || switchButton) &&
                <React.Fragment>
                  {getDossiers.map((dossier, idx) =>
                    <React.Fragment key={idx}>
                      {dossier.latitude !== null && dossier.longitude !== null &&
                        <React.Fragment>
                          {this.getMarkerDossier(dossier)}
                        </React.Fragment>
                      }
                    </React.Fragment>
                  )}
                </React.Fragment>
              }
              {(tab === 2 || switchButton) &&
                <React.Fragment>
                  {getContacts.map((contact, idx) =>
                    <React.Fragment key={idx}>
                      {contact.latitude !== null && contact.longitude !== null &&
                        <React.Fragment>
                          {this.getMarkerContact(contact)}
                        </React.Fragment>
                      }
                    </React.Fragment>
                  )}
                </React.Fragment>
              }
              {tab === 3 &&
                <React.Fragment>
                  {getRdvMarker.map((rdv, idx) =>
                    <React.Fragment key={idx}>
                      {rdv.geometry.coordinates["0"] !== null && rdv.geometry.coordinates["1"] !== null &&
                        <React.Fragment>
                          {this.getMarkerRdv(rdv)}
                        </React.Fragment>
                      }
                    </React.Fragment>
                  )}
                </React.Fragment>
              }
            </Map>
          </div>
          <div className={this.state && this.state.lengende ? "menu visible" : "menu"} id="legende">
            <div className="tirette visible" onClick={this.legendeVisible.bind(this)}><i className="fa fa-info"></i></div>
            <div className="wrapper">
              <ul>
                <h3>Les contacts</h3>
                <li><img src="img/contact-bleu-fonce.png" /><span>Contact unique bien positionné</span></li>
                <li><img src="img/contact-orange.png" /><span>Contact unique positionné sur la ville</span></li>
                <li><img src="img/group-bleu-fonce.png" /><span>Groupe de contacts bien positionné</span></li>
                <li><img src="img/group-orange.png" /><span>Groupe de contacts positionné sur la ville</span></li>
              </ul>
              <ul>
                <h3>Les financements</h3>
                <li><img src="img/euro-rouge.png" /><span>Candidat(e) à contacter</span></li>
                <li><img src="img/euro-orange.png" /><span>En attente de confirmation client</span></li>
                <li><img src="img/euro-vert-alert.png" /><span>En cours de constitution (RDV banques -30 jours)</span></li>
                <li><img src="img/euro-vert.png" /><span>En cours de constitution</span></li>
                <li><img src="img/euro-bleu-clair.png" /><span>Envoyé en banque / En attente de réponse bancaire</span></li>
                <li><img src="img/euro-bleu-fonce.png" /><span>Financé / Accordé en banque</span></li>
              </ul>
              {this.props.authStore.decodedToken &&
                <ul>
                  <h3>Les rendez-vous</h3>
                  <li><img src="img/rdv-bleu-fonce.png" /><span>Rendez-vous unique</span></li>
                  <li><img src="img/rdv-orange.png" /><span>Rendez-vous groupés</span></li>
                </ul>
              }
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default HomePage;