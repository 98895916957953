import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react/index"

import PrivateRoute from './PrivateRoute';

import HomePage from './Home/HomePage'
import ConnectedFromExtranet from './Login/ConnectedFromExtranet'
import NoLoc from './Home/No-loc'

@withRouter
@inject('authStore')
@observer
class Layout extends Component {

  render() {
    return (
      <React.Fragment>
          <Switch>
            <Route exact path="/" component={HomePage} {...this.props} />
            <Route exact path="/:token/:refresh_token" component={ConnectedFromExtranet} {...this.props} />
            <Route exact path="/:token/:refresh_token/:impersonatedEmail" component={ConnectedFromExtranet} {...this.props} />
            <PrivateRoute path="/no-loc" component={NoLoc} {...this.props} />
          </Switch>
      </React.Fragment>
    )
  }
}

export default Layout;
