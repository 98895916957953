import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

@inject('mapStore')
@observer
class SelectDepartement extends Component {

  componentWillUnmount() {
    this.props.mapStore.setDepartement('')
  }

  render() {
    const { mapStore } = this.props
    const { getAllDepartement } = this.props.mapStore

    return (
      <React.Fragment>
        <select name="departement" onChange={(event) => { mapStore.setDepartement(event.target.value) }}>
          <option value="0">Tous les départements</option>
          {getAllDepartement.map((departement, idx) => (
            <option key={idx} value={departement.label}>{departement.label}</option>
          ))}
        </select>
      </React.Fragment>
    )
  }
}

export default SelectDepartement
