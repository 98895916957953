import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import { API_HOST } from '../config/_entrypoint';

const superagent = superagentPromise(_superagent, global.Promise);

const responseBody = res => res.body;

const requests = {
  post: (url, body) =>
    superagent
      .post(`${API_HOST}${url}`, body)
      .then(responseBody)
};

const Auth = {
  login: (username, password) =>
    requests.post('/login_check', { username, password }),
  refresh: (refresh_token) =>
    requests.post('/refresh_token', { refresh_token })
};

class Agent {
  constructor(stores) {
    this.stores = stores;
  }

  headers() {
    if (this.stores.authStore.xSwitchUser !== null) {
      return (
        {
          'Authorization': 'Bearer ' + this.stores.authStore.getToken(),
          'X-Switch-User': this.stores.authStore.xSwitchUser,
          'Content-Type': 'application/json'
        }
      )
    } else {
      return (
        {
          'Authorization': 'Bearer ' + this.stores.authStore.getToken(),
          'Content-Type': 'application/json'
        }
      )
    }
  }
}

export default { Agent, Auth };

// export default { Auth, config, headers };
