import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

@inject('mapStore')
@observer
class SelectRegion extends Component {

  componentWillUnmount() {
    this.props.mapStore.setRegion('')
  }

  render() {
    const { mapStore } = this.props
    const { getAllRegion } = this.props.mapStore

    return (
      <React.Fragment>
        <select name="region" onChange={(event) => { mapStore.setRegion(event.target.value) }}>
          <option value="0">Toutes les regions</option>
          {getAllRegion.map((region, idx) => (
            <option key={idx} value={region.label}>{region.label}</option>
          ))}
        </select>
      </React.Fragment>
    )
  }
}

export default SelectRegion
