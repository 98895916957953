import React, { Component } from "react";
import { inject, observer } from "mobx-react/index";
import _ from 'lodash';
// import history from '../../../history';

@inject('authStore', 'mapStore', 'rdvStore', 'loanFileStore', 'contactStore')
@observer
class SearchAddress extends Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    this.state = {
      popupVisible: false,
      query: ''
    };
  }

  componentWillMount() {
    this.handleSearchDebounced = _.debounce(function () {
      this.props.mapStore.search(this.state.query);
    }, 500);
  }

  handleClick(event) {
    if (!this.state.popupVisible) {
      // attach/remove event handler
      this.props.mapStore.search(event.target.value);
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      popupVisible: !prevState.popupVisible,
    }));
  }

  handleOutsideClick(e) {
    this.setState(prevState => ({
      popupVisible: true,
    }))
    this.handleClick()
  }

  onChange(event) {
    if (this.props.type === 'itinary') {
      if (this.props.order === 'step' && this.props.mapStore.getStepAddressItinary[this.props.orderStep] !== undefined) {
        return null
      }
      if (this.props.order === 'first' && this.props.mapStore.getStartAddressItinary.length !== 0) {
        return null
      }
      if (this.props.order === 'finish' && this.props.mapStore.getFinishAddressItinary.length !== 0) {
        return null
      }
    }
    this.setState({ query: event.target.value })
    this.handleSearchDebounced()
  }

  onClick(x, y, label) {
    this.setState({ query: label })
    if (this.props.type === 'itinary') {
      if (this.props.order === 'first') {
        this.props.mapStore.setStartAddressItinary(x, y)
      } else if (this.props.order === 'finish') {
        this.props.mapStore.setFinishAddressItinary(x, y)
      } else if (this.props.order === 'step') {
        this.props.mapStore.setStepAddressItinary(x, y, this.props.orderStep)
      }
    } else {
      this.props.mapStore.setMarkerResultAddress(x, y)
    }
    this.props.mapStore.resetSearchResultAddress()
  }

  getClass() {
    if (this.props.type === 'itinary') {
      if (this.props.order === 'step' && this.props.mapStore.getStepAddressItinary[this.props.orderStep] !== undefined) {
        return 'input-search blocked'
      }
      if (this.props.order === 'first' && this.props.mapStore.getStartAddressItinary.length !== 0) {
        return 'input-search blocked'
      }
      if (this.props.order === 'finish' && this.props.mapStore.getFinishAddressItinary.length !== 0) {
        return 'input-search blocked'
      }
    }
    return 'input-search'
  }

  getIconStatus() {
    if (this.props.type === 'itinary') {
      if (this.props.order === 'step') {
        if (this.props.mapStore.getStepAddressItinary[this.props.orderStep] !== undefined) {
          return <i className="fa fa-times" aria-hidden="true" onClick={() => { this.deleteText() }}></i>
        } else {
          return <i className="fa fa-minus" aria-hidden="true" onClick={() => { this.deleteInput() }}></i>
        }
      } else if (this.props.order === 'first' && this.props.mapStore.getStartAddressItinary.length !== 0) {
        return <i className="fa fa-times" aria-hidden="true" onClick={() => { this.deleteText() }}></i>
      } else if (this.props.order === 'finish' && this.props.mapStore.getFinishAddressItinary.length !== 0) {
        return <i className="fa fa-times" aria-hidden="true" onClick={() => { this.deleteText() }}></i>
      }
    }
    return null
  }

  deleteText() {
    if (this.props.type === 'itinary') {
      if (this.props.order === 'step') {
        this.props.mapStore.getStepAddressItinary.splice(this.props.orderStep, 1)
      } else if (this.props.order === 'first') {
        this.props.mapStore.startAddressItinary = []
      } else if (this.props.order === 'finish') {
        this.props.mapStore.finishAddressItinary = []
      }
    }
    this.setState({ query: '' })
  }

  deleteInput() {
    if(this.props.type === 'itinary' && this.props.order === 'step') {
      this.props.mapStore.removeStep()
    }
  }

  componentWillUnmount() {
    this.props.mapStore.resetMarkerResultAddress()
    this.props.mapStore.resetAddressItinary()
  }

  render() {
    const { placeholder } = this.props
    const { getSearchResultAddress } = this.props.mapStore

    return (
      <React.Fragment>
        <div className="search" ref={node => { this.node = node }}>
          <input
            type="text"
            name="search_dossier"
            autoComplete="off"
            className={this.getClass()}
            // className="input-search fullsize"
            placeholder={placeholder ? placeholder : "Rechercher"}
            onChange={this.onChange.bind(this)}
            onClick={this.handleClick}
            value={this.state.query}
          />
          {this.getIconStatus()}
          {this.state.popupVisible && (
            <ul className="suggestions open">
              {getSearchResultAddress.map((search, idx) =>
                <li key={idx} onClick={() => this.onClick(search.geometry.coordinates["1"], search.geometry.coordinates["0"], search.properties.label)}>{search.properties.label}</li>
              )}
            </ul>
          )}
        </div>
      </React.Fragment>
    )
  }
}
export default SearchAddress;
