import React, { Component } from "react";
import { inject, observer } from "mobx-react/index";
import _ from 'lodash';
import SearchAddress from "./SearchAddress";

@inject('authStore', 'mapStore')
@observer
class SearchItinerary extends Component {

  constructor(props) {
    super(props);
  }

  addStep() {
    this.props.mapStore.addStep()
  }

  getStep() {
    let table = []
    for (let i = 0; i < this.props.mapStore.numStepAddressItinary; i++) {
      table.push(
        <SearchAddress
            placeholder="Étape"
            type="itinary"
            order="step"
            orderStep={i}
            key={i}
          />
      )
    }
    return table
  }

  render() {
    return (
      <React.Fragment>
        <div id="itineraire" className="itineraire search-box">
          <SearchAddress
            placeholder="Départ"
            type="itinary"
            order="first"
          />
          {this.getStep()}
          <button type="button" id="add-waypoint" title="Ajouter une étape" onClick={this.addStep.bind(this)}>
            <i className="fa fa-plus"></i>
          </button>
          <SearchAddress
            placeholder="Arrivée"
            type="itinary"
            order="finish"
          />
          <button className="valid" type="button" title="Afficher la route" onClick={() => {this.props.mapStore.calculateRoute()}}>
            <i className="fa fa-arrow-right"></i>
          </button>
        </div>
      </React.Fragment>
    )
  }
}
export default SearchItinerary;
